import * as React from 'react'
import { Container, Table } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementTable = ({data}) => {
    const element = JSON.parse(data.Data)
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`pt-sm element-table ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <Container>
                <div className="title text-center">
                    {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                    <div 
                        dangerouslySetInnerHTML={{__html: Content}}>    
                    </div>
                </div>
                <Table className="table-block" responsive>
                    {element.HeaderItems && (
                        <thead>
                            <tr>
                                {element.HeaderItems.map((headerItem, idx) => (
                                    <th key={`header-item-${idx}`}>{headerItem.Title}</th>
                                ))}
                            </tr>
                        </thead>
                    )}
                    {element.Rows && (
                        <tbody>
                            {element.Rows.map((row, idx) => {
                                return renderRowItems(row)                                    
                            })}
                        </tbody>
                    )}
                </Table>
            </Container>
        </section>
    )
}

function renderRowItems(row){
    var columns = Object.keys(row.RowItems).map(key => row.RowItems[key])
    return row.RowItems && (
        <tr key={`row-id-${row.ID}`}>
            {columns.map((column, idx) => {
                let contentType = column.ContentType
                let columnContent = column.Content
                switch(contentType){
                    case 'BlackDot':
                        columnContent =  '<span class="dot-black"></span>'
                        break
                    case 'WhiteDot':
                        columnContent =  '<span class="dot-circle"></span>'
                        break
                    default: break
                }
                return(
                    <td key={`row-item-${column.ID}`} dangerouslySetInnerHTML={{__html: columnContent}} />    
                )
            })}
        </tr>
    )
}
export default ElementTable
