import * as React from "react"
import { Container, Col, Row } from "react-bootstrap"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import { Link } from 'gatsby'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div className="banner-arrow next" style={{ ...style }} onClick={onClick} aria-hidden="true" />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div className="banner-arrow prev" style={{ ...style }} onClick={onClick} aria-hidden="true" />
  )
}

const ElementTextBannerSlider = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    const settings = {
        centerMode: false,
        infinite: true,
        dots: false,
        slidesToShow: 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
      }
  
    return (
        <section id={`element-${element.ID}`} className={`bg-gray element-text-banner-slider ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>   
            {element.ShowTitle === 1 && (
                <div className="title text-center">
                    <h2>{element.Title}</h2>
                </div>
            )}
            {element.Items && (
                <Slider {...settings} className="banner-slider">
                    {element.Items.map((item, idx) => {
                        return (
                            <div className="banner-item" key={`text-banner-item-${item.ID}`}>
                                <div className="banner-item-inner">
                                  <Container>
                                    <Row className="justify-content-between">
                                        <Col xs={12} lg={4}>
                                            <div className="title">
                                                <h2>{item.Title}</h2>
                                            </div>
                                        </Col>
                                        <Col xs={12} lg={5}>
                                              <div className="d-flex flex-column align-items-start">
                                                  <p>{item.Content}</p>
                                                  {(item.LinkTitle && item.Link) && (
                                                    <Link to={item.Link} target={item.Target} className="btn btn-outline-primary">{item.LinkTitle}</Link>                                                    
                                                  )}
                                              </div>
                                        </Col>
                                    </Row>
                                  </Container>
                                </div>
                                {item.Image && (
                                    <div className="banner-item-image">
                                      <img src={item.Image} alt={item.Title} />
                                    </div>
                                )}
                            </div>
                        )
                    })}                    
                </Slider>
            )}
        </section>
    )
}

export default ElementTextBannerSlider