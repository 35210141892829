import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementCataloguesBlock = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`pt-sm element-catalogues ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <Container className="catalogues">
                <div className="title">
                    {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                    {Content && (
                        <div dangerouslySetInnerHTML={{__html: Content}}/>
                    )}
                </div>
                {element.Items.length > 0 && (
                <div className="catalogues-inner">
                    <Row>
                        {element.Items.map((item, idx) => {
                            return (
                               <Col key={`catalogue-item-${item.ID}`} className="catalogues-item pb-4" xs={6} sm={6} lg={4} xl={3}>
                                    {item.Link ? (
                                        <Link to={`${process.env.SS_CMS_BASEURL}${item.Link}`} target={item.Target}>
                                            <div className="catalogues-item-img">
                                                <img src={item.Image} alt="" />
                                            </div>
                                        </Link>
                                    ):(
                                        <div className="catalogues-item-img">
                                            <img src={item.Image} alt="" />
                                        </div>
                                    )}     
                                    <p>{item.Title}</p>
                                </Col> 
                            )
                        })}
                  </Row>
                </div>
                )}
            </Container>
        </section>
    )
}
export default ElementCataloguesBlock