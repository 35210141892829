import React from "react"
import { Container, Row, Col, Nav, Tab, Accordion } from "react-bootstrap"
import { Base64 }  from '../../../helpers/base64'

const ElementFAQsBlock = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
  return (
    <section id={`element-${element.ID}`} className={`pt-sm element-faq-block ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
        <Container>
            <div className="title text-center">
                {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                {Content && (
                    <div dangerouslySetInnerHTML={{__html: Content}}/>
                )}
            </div>
            <Tab.Container id="left-tabs-example" defaultActiveKey="topic-0">
                <Row>                    
                    <Col lg={4} xl={3}>
                        {element.Topics.length > 0 && (
                        <Nav variant="pills" className="flex-column tabs-group">
                            {element.Topics.map((topic, idx) => {
                                return (
                                    <Nav.Item key={`topic-nav-${topic.ID}`}>
                                        <Nav.Link eventKey={`topic-${idx}`}>{topic.Name}</Nav.Link>
                                    </Nav.Item>
                                )
                            })}
                        </Nav>
                        )}
                    </Col>                    
                    <Col lg={8} xl={9}>
                        {element.Topics.length > 0 && (
                            <Tab.Content>
                                {element.Topics.map((topic, idx) => {                  
                                    return(
                                        <Tab.Pane eventKey={`topic-${idx}`} key={`topic-pane-${idx}`}>
                                            <Accordion className="custom-accordion" defaultActiveKey={`faq-item-0`}>
                                                <h3 className="subtitle subtitle-sm">{topic.Name}</h3>
                                                {topic.Items.length > 0 && (
                                                    <>
                                                    {topic.Items.map((topicItem, topicItemIdx) => { 
                                                        var answer = Base64.decode(topicItem.Answer)      
                                                        return(
                                                            <Accordion.Item eventKey={`faq-item-${topicItemIdx}`} key={`topic-item-${topicItemIdx}`}>
                                                                <Accordion.Header>
                                                                    {topicItem.Question}
                                                                </Accordion.Header>
                                                                <Accordion.Body dangerouslySetInnerHTML={{__html: answer}}>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        )
                                                    })} 
                                                    </>
                                                )}
                                            </Accordion>
                                        </Tab.Pane>
                                    ) 
                                })}
                            </Tab.Content>
                        )}
                    </Col>
              </Row>
            </Tab.Container>
          </Container>
    </section>
  )
}

export default ElementFAQsBlock