import React, { useEffect, useState, useRef } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import axios from "axios"
import ReCAPTCHA from "react-google-recaptcha"
import { Base64 }  from '../../../helpers/base64'

const ElementUserForm = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var htmlForm = Base64.decode(element.HTMLForm)
    const host = process.env.SS_CMS_BASEURL
    const formType = 'ElementForm'
    const [error, setError] = useState(null)
    const [captcha, setCaptcha] = useState(null)
    const recaptchaRef = useRef(null)
    const onChangeRecaptcha = (value) => {
        if(value){
            setCaptcha(value)
        }
    }
        
    const pageURL = typeof window !== 'undefined' ? window.location.href : ''
    let page_url_field = document.getElementsByName('page_url')
    if(page_url_field.length > 0){
        page_url_field[0].value = pageURL
    }
    const handleSubmit = e => {
        e.preventDefault()
        if(!captcha){
            setError('Invalid Recaptcha')
            return
        }
        const formData = new FormData(e.target)
        formData.append('PageID', element.ID)
        formData.append('FormType', formType)
        axios.post(host + "/grocorpapi/userform/", formData).then(response => {
            let _response_data = response.data
            if(_response_data.Success === 1){
              if (_response_data.RedirectLink) {
                window.location.href = _response_data.RedirectLink
              }else if(_response_data.ThanksMessage){
                let thanksMessage = Base64.decode(_response_data.ThanksMessage)
                document.getElementById("UserForm_Form_" + element.ID).innerHTML = thanksMessage
              }
            }else if(_response_data.Success === 0){
                setError(_response_data.Message)
                let top = document.getElementById('userform').offsetTop
                if(typeof top !== 'undefined'){
                    let scrollTo = top + 600
                    window.scroll({
                        top: scrollTo,
                        behavior: "smooth"
                    })
                }
            }
        }).catch(function (error) {
        })
    }
    return (
        <section id={`element-${element.ID}`} className={`element-userform ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>    
            <Container>
                <div className="title text-center">
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                </div>
                <Row className="justify-content-center">
                  <Col xs={12} xl={10} xxl={8}>
                        {error && (<p className="message error text-danger">{error}</p>)}
                        <form id={`UserForm_Form_${element.ID}`} 
                        encType="application/x-www-form-urlencoded" 
                        onSubmit={e => handleSubmit(e)}
                        className="userform"
                        >
                            <div id="userform"dangerouslySetInnerHTML={{__html: htmlForm}} /> 
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6Le7d2ogAAAAAA7GwZ9FmhtCu2L3ZXaO0ctlipA0"
                                onChange={onChangeRecaptcha}
                              />
                            <div className="mt-2rem">	
                                <input type="submit" 
                                name="action_customProcess" 
                                value="Submit" 
                                className="action btn btn-outline-primary btn-w200 mx-auto mt-3" 
                                id={`UserForm_Form_${element.ID}_action_customProcess`}/>
                            </div>
                        </form>
                  </Col>
                </Row>
              </Container>
        </section>
    )
}

export default ElementUserForm