import * as React from "react"
import { Container, Col, Row } from "react-bootstrap"
import Icon from "../../../images/icons.svg"
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementDocument = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`pt-0 pb-0 element-document ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <div className="document">
                <Container>
                    <Row>
                      <Col xs={12} lg={6}>
                        <p className="h5">DETAILS</p>
                        <div className="document-content">
                              {element.SubTitle && (<h5>{element.SubTitle}</h5>)}
                              {Content && (
                                  <div dangerouslySetInnerHTML={{__html: Content}}/>
                              )}
                              {(element.DocumentFile && element.DocumentButtonTitle) && (
                                  <Link to={element.DocumentFile} target="_blank" className="btn btn-outline-primary">
                                      <svg focusable="false" className="me-3" width="24" height="24">
                                        <use xlinkHref={`${Icon}#icon-download`}></use>
                                      </svg>
                                      {element.DocumentButtonTitle}
                                  </Link>
                              )}
                            </div>
                      </Col>
                    </Row>
                </Container>
                {element.RightImage && (
                    <div className="document-image">
                      <img src={element.RightImage} alt="" />
                    </div>
                )}
              </div>
        </section>
    )
}

export default ElementDocument