import * as React from "react"
import { Container, Col, Row, Form } from "react-bootstrap"
import Icon from "../../../images/icons.svg"
import { Base64 }  from '../../../helpers/base64'

const ElementNewsletter = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var inlineStyleBgImage = {}
    if(element.BackgroundImage){
        inlineStyleBgImage.backgroundImage = "url(" + element.BackgroundImage + ")"
    }    
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`pb-sm pt-0 element-newsletter ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <div className="subscribe" style={inlineStyleBgImage}>
            <Container>
                <Row className="justify-content-lg-end justify-content-center">
                    <Col xs={12} sm={11} lg={8} xl={6} xxl={5}>
                        <div className="subscribe-inner">
                            {element.ShowTitle === 1 && (<h2 className="h3">{element.Title}</h2>)}
                            <div dangerouslySetInnerHTML={{__html: Content}}/>    
                            <Form className="subscribe-form">
                                <div className="form-floating">
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="subscribeEmail"
                                      placeholder="Enter your email"
                                    />
                                    <label htmlFor="subscribeEmail">Enter your email</label>
                                </div>
                                <button className="subscribe-submit">
                                    <svg focusable="false" width="19" height="14">
                                      <use xlinkHref={`${Icon}#icon-long-arrow`}></use>
                                    </svg>
                                </button>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </Container>
          </div>
        </section>
    )
}

export default ElementNewsletter