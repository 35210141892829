import React, { useMemo, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Base64 }  from '../../../helpers/base64'

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: state.isFocused ? 0 : 0,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  indicatorSeparator: () => ({ display: "none" }),
  menu: (base) => ({
    ...base,
    width: "max-content",
    zIndex: 10,
  }),
  container: (provided) => ({
    ...provided,
    minWidth: 125,
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused
      ? "rgba(0, 0, 0, 0.5)"
      : isSelected
      ? "rgba(0, 0, 0, 1)"
      : "rgba(0, 0, 0, 0)",
    color: isFocused ? "#ffffff" : isSelected ? "#ffffff" : undefined,
  }),
};

const options = [
  { value: "all", label: "All" },
  { value: "name", label: "Name" },
  { value: "position", label: "Position" },
];

const ElementOurTeam = ({ data }) => {
  const element = JSON.parse(data.Data);
  var inlineStyle = {};
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  const teamMembers = element.TeamMembers;
  const [sort, setSort] = useState(null);
  if (teamMembers && sort) {
    teamMembers.sort((a, b) => {
      if (sort === "name") {
        return a.Name > b.Name ? 1 : -1;
      } else if (sort === "position") {
        return a.Position > b.Position ? 1 : -1;
      }
    });
  }
  var Content = Base64.decode(element.Content);
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-our-team ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container className="ourteam">
        <div className="title text-center">
          {element.SubTitle && (
            <p className="subtitle subtitle-sm">{element.SubTitle}</p>
          )}
          {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
          {Content && <div dangerouslySetInnerHTML={{ __html: Content }} />}
        </div>
        <div className="filter">
          <div className="filter-select">
            <p className="h5">SORT BY</p>
            <Select
              defaultValue={{ value: "all", label: "All" }}
              isSearchable={false}
              styles={customStyles}
              options={options}
              onChange={(e) => setSort(e.value)}
            />
          </div>
        </div>
        {teamMembers && (
          <div className="ourteam-inner">
            <Row>
              {teamMembers.map((teamMember, idx) => {
                return (
                  <Col
                    key={`team-member-${teamMember.ID}`}
                    className="ourteam-item"
                    xs={6}
                    sm={6}
                    lg={4}
                    xl={3}
                  >
                    <div className="ourteam-item-img">
                      <img alt="" src={teamMember.Image} />
                    </div>
                    <h4>{teamMember.Name}</h4>
                    <p>{teamMember.Position}</p>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      </Container>
    </section>
  );
};

export default ElementOurTeam;
