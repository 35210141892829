import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementFourColumnText = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  var Col1Content = Base64.decode(element.Col1Content)
  var Col2Content = Base64.decode(element.Col2Content)
  var Col3Content = Base64.decode(element.Col3Content)
  var Col4Content = Base64.decode(element.Col4Content)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-four-columns-text ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        {element.SubTitle && <h3 className="sub-title">{element.SubTitle}</h3>}
        {element.ShowTitle === 1 && (
          <h2 className="font-butler text-transform mb-3">{element.Title}</h2>
        )}
        <Row>
          <Col className="mb-4 mb-lg-0" xs={12} lg={6} xl={3}>
            <div dangerouslySetInnerHTML={{ __html: Col1Content }}></div>
          </Col>
          <Col className="mb-4 mb-lg-0" xs={12} lg={6} xl={3}>
            <div dangerouslySetInnerHTML={{ __html: Col2Content }}></div>
          </Col>
          <Col className="mb-4 mb-lg-0" xs={12} lg={6} xl={3}>
            <div dangerouslySetInnerHTML={{ __html: Col3Content }}></div>
          </Col>
          <Col className="mb-4 mb-lg-0" xs={12} lg={6} xl={3}>
            <div dangerouslySetInnerHTML={{ __html: Col4Content }}></div>
          </Col>
        </Row>
        {element.Buttons.length > 0 && (
          <div className="btn-wrapper">
            {element.Buttons.map((button, idx) => (
              <a
                key={`button${idx}`}
                target={button.Target}
                href={button.Link}
                className="btn btn-outline-primary btn-w200 mt-3 mt-lg-4"
              >
                {button.Title}
              </a>
            ))}
          </div>
        )}
      </Container>
    </section>
  )
}

export default ElementFourColumnText
