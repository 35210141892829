import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementTextColumns = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    const Layout = element.Layout
    const layoutMap = {
        'ThreeColumn': 4,
        'FourColumn': 3,
        'FiveColumn': 2,
    }
    
    var Column = typeof layoutMap[Layout] !== 'undefined' ? layoutMap[Layout] : 3
    return (
        <section id={`element-${element.ID}`} className={`pt-sm element-text-columns ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <Container>
                <div className="title text-center">
                    {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                    {element.Content && (
                        <div dangerouslySetInnerHTML={{__html: Content}}/>
                    )}
                </div>
                {element.Items && (
                    <div className="services">
                        <Row>
                            {element.Items.map((item, idx) => {
                                return(
                                    <Col className="services-item mt-5 mb-4" xs={12} lg={Column} key={`text-column-item-${item.ID}`}>
                                        <h3>{item.Title}</h3>
                                        <p>{item.Content}</p>
                                        {item.Link && (
                                            <a href={item.Link} target={item.Target} className="cta">
                                                {item.LinkTitle}
                                            </a>
                                        )}
                                  </Col>
                                )
                            })}
                        </Row>
                    </div>
                )}
          </Container>
        </section>
    )
}
export default ElementTextColumns