import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementThreeColumnText = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  var LeftContent = Base64.decode(element.LeftContent)
  var MiddleContent = Base64.decode(element.MiddleContent)
  var RightContent = Base64.decode(element.RightContent)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-three-columns-text ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        {element.SubTitle && (
          <h3 className="sub-title text-center">{element.SubTitle}</h3>
        )}
        {element.ShowTitle === 1 && (
          <h2 className="font-butler text-transform text-center mb-3">
            {element.Title}
          </h2>
        )}
        <Row>
          <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
            <div dangerouslySetInnerHTML={{ __html: LeftContent }}></div>
          </Col>
          <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
            <div dangerouslySetInnerHTML={{ __html: MiddleContent }}></div>
          </Col>
          <Col className="mb-4 mb-lg-0" xs={12} lg={4}>
            <div dangerouslySetInnerHTML={{ __html: RightContent }}></div>
          </Col>
        </Row>
        {element.Buttons.length > 0 && (
          <div className="btn-wrapper">
            {element.Buttons.map((button, idx) => (
              <a
                key={`button${idx}`}
                target={button.Target}
                href={button.Link}
                className="btn btn-outline-primary btn-w200 mt-3 mt-lg-4"
              >
                {button.Title}
              </a>
            ))}
          </div>
        )}
      </Container>
    </section>
  )
}

export default ElementThreeColumnText
