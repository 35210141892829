import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementIconColumns = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  var layout_map = {
    ThreeColumn: 'col-lg-4',
    FourColumn: 'col-lg-3',
    FiveColumn: 'col-lg',
  }
  var layoutColumn =
    typeof layout_map[element.Layout] !== 'undefined'
      ? layout_map[element.Layout]
      : 'col-lg'
  var Content = Base64.decode(element.Content)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-icon-columns ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        <div className="title text-center">
          {element.SubTitle && (
            <p className="subtitle subtitle-sm">{element.SubTitle}</p>
          )}
          {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
          {Content && <div dangerouslySetInnerHTML={{ __html: Content }} />}
        </div>
        {element.Items && (
          <div className="icons">
            <Row>
              {element.Items.map((item, idx) => {
                  var itemContent = Base64.decode(item.Content)
                return (
                  <Col
                    className={`icons-item ${layoutColumn}`}
                    xs={12}
                    key={`icon-item-${item.ID}`}
                  >
                    <div className="icons-image">
                      <img alt="" src={item.Icon} />
                    </div>
                    <h3>{item.Title}</h3>
                    <div dangerouslySetInnerHTML={{__html: itemContent}}/>
                  </Col>
                )
              })}
            </Row>
            {element.CTATitle && element.CTALink && (
              <div className="btn-wrapper">
                <Link
                  to={element.CTALink}
                  target={element.CTATarget}
                  className="btn btn-outline-primary btn-w180 mx-auto"
                >
                  {element.CTATitle}
                </Link>
              </div>
            )}
          </div>
        )}
      </Container>
    </section>
  )
}
export default ElementIconColumns
