import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Base64 }  from '../../../helpers/base64'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div className="circle-arrow next" style={{ ...style }} onClick={onClick} />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div className="circle-arrow prev" style={{ ...style }} onClick={onClick} />
  )
}

const ElementTestimonials = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  const settings = {
    centerMode: true,
    centerPadding: '28%',
    infinite: true,
    dots: true,
    slidesToShow: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          centerPadding: '10%',
        },
      },
    ],
  }

  var Content = Base64.decode(element.Content)

  return (
    <section
      id={`element-${element.ID}`}
      className={`bg-gray element-testimonials ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <div className="title text-center">
        {element.SubTitle && (
          <p className="subtitle subtitle-sm">{element.SubTitle}</p>
        )}
        {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
        {Content && <div dangerouslySetInnerHTML={{ __html: Content }} />}
      </div>
      {element.Items.length > 0 && (
        <Slider {...settings} className="testimonials-slider">
          {element.Items.map((item, idx) => {
            return (
              <div
                key={`testimonial-item-${item.ID}`}
                className="testimonials-item"
              >
                <div className="avt">
                  <img src={item.Image} alt="" />
                </div>
                <p className="subtitle subtitle-md">“{item.TestimonialText}”</p>
                <p className="h5">{item.CustomerName}</p>
                <p>{item.CustomerNote}</p>
              </div>
            )
          })}
        </Slider>
      )}
    </section>
  )
}

export default ElementTestimonials
