import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementTilesBlock = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    if(element.Layout === 'TitleAtBottom'){
        return (
            <section id={`element-${element.ID}`} className={`element-tiles-title-at-bottom ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>   
                <Container>
                    <div className="title text-center">
                        {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                        {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                        {Content && (
                            <div dangerouslySetInnerHTML={{__html: Content}}/>
                        )}
                    </div>
                    {element.Items.length > 0 && (
                    <Row className="tile-wrap">
                        {element.Items.map((tile, idx) => {
                           return(
                                <Col className="mb-5" xs={12} lg={4} key={`tile-item-${tile.ID}`}>
                                    <Link to={tile.Link} target={tile.Target} className="tile-item">
                                        <div className="tile-img">
                                          <img src={tile.Image} alt="" />
                                        </div>
                                        <h4 className="cta">{tile.Title}</h4>
                                    </Link>
                                </Col>
                            ) 
                        })}
                    </Row>
                    )}
              </Container>
            </section>
        )
    }else{
        return(
            <section id={`element-${element.ID}`} className={`element-tiles-title-at-top ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
                <div className="container-md horizontal-wrap">
                    <div className="title text-center">
                        {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                        {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                        {Content && (
                            <div dangerouslySetInnerHTML={{__html: Content}}/>
                        )}
                    </div>
                    {element.Items.length > 0 && (
                    <div className="ourteam-inner">
                        <Row className="horizontal-inner">
                            {element.Items.map((tile, idx) => {
                                return(
                                    <Col md={4} className="horizontal-item" key={`tile-item-title-bottom-${tile.ID}`}>
                                        <Link to={tile.Link} target={tile.Target} className="our-range-item">
                                            <img src={tile.Image} alt="" />
                                            <div className="our-range-item-inner">
                                                <p className="h4 cta">{tile.Title}</p>
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            })}                      
                        </Row>
                    </div>
                    )}
                </div>
            </section>
        )
    }
}

export default ElementTilesBlock