import React from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Base64 }  from '../../../helpers/base64'

const ElementContent = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
  return (
    <section id={`element-${element.ID}`} className={`pt-sm element-content ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
        <Container>
            <div className={`title ${element.LayoutClass} ${element.AlignClass}`}>
                {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
            </div>
            <Row>
                <Col className="mb-4 mb-lg-0" xs={12} md={12} lg={6} xl={12}>
                    <div 
                        dangerouslySetInnerHTML={{__html: Content}}>    
                    </div>
                  </Col>
            </Row>
        </Container>
    </section>
  )
}

export default ElementContent
