import React from 'react'
import { Base64 }  from '../../../helpers/base64'

const ElementCallToAction = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
  if (element.BackgroundColour != null) {
    inlineStyle.backgroundColor = element.BackgroundColour
    if(element.BackgroundColour === '#000000'){
        blackBGClass = ' section-black'
    }
  }
  var inlineStyleBgImage = {}
  if (element.BackgroundImage) {
    inlineStyleBgImage.backgroundImage = 'url(' + element.BackgroundImage + ')'
  }
  var Content = Base64.decode(element.Content)
  return (
    <div
      id={`element-${element.ID}`}
      className="element element-call-to-action"
    >
      <div
        className={`section pt-5 ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
        style={inlineStyle}
      >
        {element.ShowTitle === 1 && (
          <h2 className="font-butler text-transform mb-3 text-center">
            {element.Title}
          </h2>
        )}
        <div className="background-block" style={inlineStyleBgImage}>
          <div className="content">
            {element.SubTitle && <h3>{element.SubTitle}</h3>}
            <div
              className="small-width"
              dangerouslySetInnerHTML={{ __html: Content }}
            ></div>
            {element.Buttons.length > 0 && (
              <div className="btn-wrapper">
                {element.Buttons.map((button, idx) => (
                  <a
                    key={`button${idx}`}
                    target={button.Target}
                    href={button.Link}
                    className="color-black hover-link text-transform text-underline pr-2"
                  >
                    {button.Title}
                  </a>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ElementCallToAction
