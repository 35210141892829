import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementMostRecentCatalogue = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`element-most-recent-catalogue ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>   
            <Container>
                <Row>
                  <Col xs={12} md={4} lg>
                        <div className="title narrow">
                            {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                            {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                            {Content && (
                                <div dangerouslySetInnerHTML={{__html: Content}}/>
                            )}
                            {(element.CTATitle && element.CTALink) && (
                                <Link to={element.CTALink} target={element.CTATarget} className="btn btn-outline-primary mb-md-5">{element.CTATitle}</Link>
                            )}
                        </div>
                        <img src={element.Image1} className="w-100 mt-3 mt-md-5" alt="" />
                  </Col>
                    <Col xs={12} md={3} lg={"auto"}>
                        <div className="h-100 d-flex align-items-center">
                            <img src={element.Image2} className="w-100 mt-3" alt="" />
                        </div>
                    </Col>
                    <Col xs={12} md={5} lg>
                        <img src={element.Image3} className="w-100 mt-3 mb-3" alt="" />
                        {element.Quote && (
                            <p className="subtitle subtitle-lg">
                                {element.Quote}
                            </p>
                        )}
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ElementMostRecentCatalogue