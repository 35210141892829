import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import { Base64 }  from '../../../helpers/base64'

const ElementMapBlock = ({data}) => {
    const element = JSON.parse(data.Data)
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`element-map-block ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}> 
            <Container>
                <div className="title text-center small-spacing">                    
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                    {element.SubTitle && (<p className="subtitle subtitle-lg">{element.SubTitle}</p>)}
                    {element.Address && (<p>{element.Address}</p>)}
                    {element.ABN && (<p>ABN. {element.ABN}</p>)}
                    {element.Phone && (<p>T. {element.Phone}</p>)}
                    {element.Email && (<p>E. {element.Email}</p>)}
                    {Content && (
                        <div dangerouslySetInnerHTML={{__html: Content}}/>
                    )}
                </div>                
                <div className="map-wrapper">
                    <iframe title={element.Title} src={element.IFrameURL} width="100%" height="600px"></iframe>
                </div>
            </Container>            
        </section>            
    )
}

export default ElementMapBlock