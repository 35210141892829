import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import { Link } from 'gatsby'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="circle-arrow next"
      style={{ ...style }}
      onClick={onClick}
      aria-hidden="true"
    />
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div
      className="circle-arrow prev"
      style={{ ...style }}
      onClick={onClick}
      aria-hidden="true"
    />
  )
}

const ElementProjectsSlider = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  const infinity = element.Items.length > 4
  const settings = {
    centerMode: false,
    infinite: infinity,
    dots: false,
    slidesToShow: 4,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
    ],
  }
  return (
    <section
      id={`element-${element.ID}`}
      className={`bg-gray pb-sm element-projects-slider ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container className="projects">
        <Row>
          <Col
            xs={12}
            className="order-2 order-lg-1 d-flex justify-content-lg-end"
          >
            {element.CTATitle && element.CTALink && (
              <Link
                to={element.CTALink}
                target={element.CTATarget}
                className="btn btn-outline-primary cta mb-4"
              >
                {element.CTATitle}
              </Link>
            )}
          </Col>
          <Col xs={12} lg={3} className="order-1 order-lg-2 position-relative">
            {element.ShowTitle === 1 && (
              <div className="title rotate">
                <h2>{element.Title}</h2>
              </div>
            )}
          </Col>
          {element.Items && (
            <Col xs={12} lg={9} className="order-3">
              <Slider {...settings} className="projects-slider">
                {element.Items.map((item, idx) => {
                  return (
                    <div
                      className="projects-item"
                      key={`project-slider-${item.ID}`}
                    >
                      <Link
                          to={item.Link}
                          target={item.Target}
                        >
                        <div className="projects-img">
                          <img src={item.Image} alt={item.Title} />
                        </div>
                      </Link>
                      {item.LinkTitle && item.Link && (
                        <Link
                          to={item.Link}
                          target={item.Target}
                          className="h4 cta"
                        >
                          {item.LinkTitle}
                        </Link>
                      )}
                    </div>
                  )
                })}
              </Slider>
            </Col>
          )}
        </Row>
      </Container>
    </section>
  )
}

export default ElementProjectsSlider
