import React from 'react';
import { Container } from 'react-bootstrap'
import { Link } from 'gatsby'
import Slider from "react-slick"

const ElementLatestBlog = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    
    const settings = {
        slidesToShow: 1,
        speed: 500,
        rows: 3,
        slidesPerRow: 3,
        arrows: false,
        dots: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesPerRow: 3,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesPerRow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesPerRow: 1,
              }
            }
        ],
        appendDots: dots => (
            <ul> {dots} </ul>
        ),
        customPaging: i => (
          <div
          >
            {i + 1}
          </div>
        )
      }
    return (
        <div className="element element-latest-catalogues">
            <div className={`section ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
                <Container>
                    {element.ShowTitle === 1 && (<h2 className="font-butler text-transform mb-3">{element.Title}</h2>)}
                    {element.BlogPosts && (
                    <div className="blog-list">
                        <Slider {...settings}>
                            {element.BlogPosts.map((item, idx) => {
                                return(
                                    <div className="item" key={`blogentry-item-${item.ID}`}>
                                        <div className="images">
                                            <Link to={item.Link}><img src={item.ThumbnailImage} alt="" className="img" /></Link>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <p className="font-16 font-amiri text-400 mb-0">{item.Category}</p>
                                            <p className="font-16 font-amiri text-400 color-dark-grey mb-0">{item.Date}</p>
                                        </div>
                                        <h2 className="font-22"><Link to={item.Link} className="color-black hover-link font-22">{item.Title}</Link></h2>
                                        <p>{item.Summary}</p>
                                        <Link to={item.Link} className="text-transform color-black text-underline hover-link">READ MORE</Link>
                                    </div>
                                )
                            })}                            
                        </Slider>
                    </div>
                    )}
                </Container>
            </div>
        </div>
    )
}

export default ElementLatestBlog