import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'gatsby'
import TimelineObserver from 'react-timeline-animation'
import { Base64 } from '../../../helpers/base64'

const Timeline = ({ items, setObserver, callback }) => {
  const [timelineDisplay, setTimelineDisplay] = useState(true)
  var visibles = []
  var timelines = []
  var circles = []

  if (items.length) {
    var i = 0

    for (; items[i]; ) {
      visibles[i] = false
      timelines[i] = null
      circles[i] = null
      i++
    }
  }

  const triggerVisible = i => {
    visibles[i] = true
  }

  useEffect(() => {
    if (timelines.length) {
      let i = 0
      for (; timelines[i]; ) {
        setObserver(timelines[i].current)
        i++
      }
    }
    if (circles.length) {
      let i = 0
      for (; circles[i]; ) {
        setObserver(circles[i].current, triggerVisible(i))
        i++
      }
    }
  }, [])

  const toggleTimeline = () => {
    setTimelineDisplay(current => !current)
  }

  return (
    <>
      <div className="timeline-wrapper">
        {items.map((item, idx) => {
          let myTimeline = timelines[idx]
          let myCircle = circles[idx]
          return (
            <div
              key={`timeline-item-${item.ID}`}
              className={`timeline-item${
                idx > 1 && timelineDisplay ? ' d-none' : ''
              }`}
            >
              <div
                id={`timeline${idx}`}
                ref={myTimeline}
                className="timeline-stroke"
              >
                <div id={`circle${idx}`} ref={myCircle} className="circle" />
              </div>
              <div className={`timeline-content visible`}>
                <div className="timeline-content-img">
                  <h3>{item.Year}</h3>
                  <img alt="" src={item.Image} />
                </div>
                <div className="timeline-content-inner">
                  {item.ShowTitle === 1 && <h4 className="h5">{item.Title}</h4>}
                  <p>{item.Description}</p>
                  {item.Link && (
                    <a href={item.Link} target={item.Target} className="cta">
                      {item.LinkTitle}
                    </a>
                  )}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="btn-wrapper pt-4 pt-lg-5">
        <button
          className="btn btn-outline-primary btn-w180"
          onClick={toggleTimeline}
        >
          View {timelineDisplay ? 'More' : 'Less'}
        </button>
      </div>
    </>
  )
}

const ElementTimelineBlock = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
  if (element.BackgroundColour != null) {
    inlineStyle.backgroundColor = element.BackgroundColour
    if (element.BackgroundColour === '#000000') {
      blackBGClass = ' section-black'
    }
  }
  var Content = Base64.decode(element.Content)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-timeline ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        <div className="title">
          {element.SubTitle && (
            <p className="subtitle subtitle-sm">{element.SubTitle}</p>
          )}
          {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
          {element.Content && (
            <div dangerouslySetInnerHTML={{ __html: Content }} />
          )}
        </div>
        <div className="timeline-wrapper">
          <Row className="justify-content-center">
            <Col xs={12} lg={11} xl={10} xxl={9}>
              <div className="timeline-item blank">
                <div className="timeline-stroke" />
              </div>
              <TimelineObserver
                initialColor="#e5e5e5"
                fillColor="black"
                handleObserve={setObserver => (
                  <Timeline
                    className="timeline-stroke"
                    setObserver={setObserver}
                    items={element.Items}
                  />
                )}
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default ElementTimelineBlock
