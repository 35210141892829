import React, { useState, useCallback } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Gallery from "react-photo-gallery"
import Carousel, { Modal, ModalGateway } from "react-images"
import Slider from "react-slick"
import { Base64 }  from '../../../helpers/base64'

function CustomNextArrow(props) {
  const { style, onClick } = props
  return (
    <div className="circle-arrow next" style={{ ...style }} onClick={onClick} aria-hidden="true"/>
  )
}

function CustomPrevArrow(props) {
  const { style, onClick } = props
  return (
    <div className="circle-arrow prev" style={{ ...style }} onClick={onClick} aria-hidden="true"/>
  )
}

const ElementGalleryImage = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)
    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])

    const closeLightbox = () => {
      setCurrentImage(0)
      setViewerIsOpen(false)
    }
    var Content = Base64.decode(element.Content)
    if(element.DisplayOption === 'Tiles'){
        return (
            <section id={`element-${element.ID}`} className={`pb-sm element-gallery-image tiles-gallery ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>  
                <Container>
                    <div className="title text-center">
                        {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                        {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                        {Content && (
                            <div dangerouslySetInnerHTML={{__html: Content}}/>
                        )}
                    </div>
                    {element.Items.length > 0 && (
                        <div className="gallery-categories">
                            <Row>
                                {element.Items.map((item, idx) => {
                                    return (
                                        <Col key={`gallery-item-${item.ID}`} className="gallery-category-item" xs={6} md={6} lg={4}>
                                            {item.Link ? (
                                                <a className="d-block text-decoration-none" href={item.Link} target={item.Target}>
                                                    <div className="gallery-category-item-image">
                                                      <img alt="" src={item.Image} />
                                                    </div>
                                                    <h4>{item.Title}</h4>
                                                </a>  
                                            ):(
                                                <>
                                                <div className="gallery-category-item-image">
                                                  <img alt="" src={item.Image} width={item.ImageWidth} height={item.ImageHeight} />
                                                </div>
                                                <h4>{item.Title}</h4>
                                                </>
                                            )}
                                        </Col>
                                    )
                                })}                        
                            </Row>
                        </div>
                    )}
                </Container>
            </section>
        )
    }else if(element.DisplayOption === 'LightboxGallery'){
        var photos = []
        var orgPhotos = []
        if(element.Items.length){
            let i = 0
            for (; element.Items[i];) {
                let photoItem = {
                    src: element.Items[i].Image,
                    width: element.Items[i].ImageWidth,
                    height: element.Items[i].ImageHeight,
                }
                photos.push(photoItem)
                
                let orgPhotoItem = {
                    src: element.Items[i].OrigitalImage,
                    width: element.Items[i].ImageWidth,
                    height: element.Items[i].ImageHeight,
                }
                orgPhotos.push(orgPhotoItem)
                i++
            }
        }
        return(
            <section id={`element-${element.ID}`} className={`pb-sm element-gallery-image lightbox-gallery ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>  
                <Container>
                    <div className="title text-center">
                        {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                        {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                        {Content && (
                            <div dangerouslySetInnerHTML={{__html: Content}}/>
                        )}
                    </div>
                    {photos.length > 0 && (
                        <div className="gallery-wrap">
                          <Gallery
                            photos={photos}
                            onClick={openLightbox}
                            margin={10}
                            targetRowHeight={447}
                          />
                          <ModalGateway>
                            {viewerIsOpen ? (
                              <Modal onClose={closeLightbox}>
                                <Carousel
                                  currentIndex={currentImage}
                                  views={orgPhotos.map((x) => ({
                                    ...x,
                                  }))}
                                />
                              </Modal>
                            ) : null}
                          </ModalGateway>
                        </div>
                    )}
                  </Container>
            </section>
        )
    }else if(element.DisplayOption === 'FullWidthCarousel'){
        const carousel_settings = {
            dots: false,
            slidesToShow: 1,
            nextArrow: <CustomNextArrow />,
            prevArrow: <CustomPrevArrow />,
            speed: 500,
        }
        return(
            <section id={`element-${element.ID}`} className={`element-gallery-image full-width-carousel-gallery ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
                {element.Items.length > 0 && (
                    <Slider {...carousel_settings} className="full-image-slider">
                        {element.Items.map((item, idx) => {
                            return(
                                <img key={`carousel-image-${item.ID}`} alt="" src={item.OrigitalImage} />
                            )
                        })}
                    </Slider>
                )}
            </section>
        )
    }
}

export default ElementGalleryImage