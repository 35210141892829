import React, { useState, useEffect, useCallback } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'
import { formatPrice } from '../../../helpers/price'
import {
  getProductUrl,
  buildProductName,
  getProductUrlByCategory,
} from '../../../helpers/theme'
import Product from '../../../models/product'
import { Base64 } from '../../../helpers/base64'
import ProductThumbnail from '../../../components/Product/Thumbnail'
import FeaturedProductModal from '../../../components/FeaturedProduct/FeaturedProductModal'
import { useSelector } from 'react-redux'

function isValidJSONString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

const ElementFeaturedProducts = ({ data }) => {
  const { items: priceRuleItems } = useSelector(state => state.priceRule)
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
  if (element.BackgroundColour != null) {
    inlineStyle.backgroundColor = element.BackgroundColour
    if (element.BackgroundColour === '#000000') {
      blackBGClass = ' section-black'
    }
  }
  var Content = Base64.decode(element.Content)
  const [products, setProducts] = useState([])
  const [modalShow, setModalShow] = useState(false)
  const [popupItem, setPopupItem] = useState(false)

  const ShowPopup = item => e => {
    e.preventDefault()
    setPopupItem(item)
    setModalShow(true)
  }
  useEffect(() => {   
    var productsIDs = JSON.parse(element.ProductsIDs.trim())
    var productsIDsObjects = Object.keys(productsIDs).map(key => productsIDs[key])
    fetch('/products.json')
      .then(res => res.json())
      .then(res => {
        setProducts(res.filter(_item => {
              return productsIDsObjects.includes(_item.id.toString())
        }))
      })
  }, [])

  return (
    <section
      id={`element-${element.ID}`}
      className={`element-featured-products ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        <div className="title text-center">
          {element.SubTitle && (
            <p className="subtitle subtitle-sm">{element.SubTitle}</p>
          )}
          {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
          {Content && <div dangerouslySetInnerHTML={{ __html: Content }} />}
        </div>
        {products && products.length > 0 && (
          <Row className="justify-content-center">
            {products.map((_item, idx) => {
              const item = new Product(_item, priceRuleItems)
              let nameArray = buildProductName(item.name)
              const productName = nameArray[0]
              let exclGSTPrice = null
              let productURL = getProductUrl(_item)
              let product = new Product(item, priceRuleItems)
              let price = parseFloat(
                Math.round(product.price * 1000) / 1000
              ).toFixed(2)
              price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

              let sale_price = parseFloat(
                Math.round(product.salePrice * 1000) / 1000
              ).toFixed(2)
              sale_price = sale_price
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

              return (
                <Col xs={6} md={6} lg={3} key={`featured-product-${item.id}`}>
                  <Link to={getProductUrlByCategory(_item)}>
                    <div className="featured-item">
                      <div className="featured-item-inner">
                        <div className="featured-item-image">
                          <ProductThumbnail product={item} />
                          {item.stock_status === 'OUT_OF_STOCK' && (
                            <span className="tags">OUT OF STOCK</span>
                          )}
                          <button
                            className="featured-item-view"
                            onClick={ShowPopup(item)}
                          >
                            Quick View
                          </button>
                        </div>
                        <div className="h4">{productName}</div>
                      </div>
                      <div className="featured-item-des">
                        {item.isBundle || item.isConfigurable ? (
                          <Row>
                            <Col xs={12} md={6}>
                              <strong className="price-label">
                                {item.isBundle && <span>From:&nbsp;</span>}
                                {formatPrice(
                                  item.minPrice + (item.minPrice * 10) / 100,
                                  item.currency
                                )}
                                {item.minFullPrice > item.minPrice && (
                                  <>
                                    <br />
                                    <s className="old-price">
                                      {formatPrice(
                                        item.minFullPrice +
                                          (item.minFullPrice * 10) / 100,
                                        item.currency
                                      )}
                                    </s>
                                  </>
                                )}
                              </strong>

                              <p className="text-sm">
                                Excl. GST:{' '}
                                {formatPrice(item.minPrice, item.currency)}
                              </p>
                            </Col>
                            {item.maxPrice > item.minPrice && (
                              <Col xs={12} md={6}>
                                <strong className="price-label">
                                  {item.isBundle && <span>To:&nbsp;</span>}
                                  {formatPrice(
                                    item.maxPrice + (item.maxPrice * 10) / 100,
                                    item.currency
                                  )}
                                  {item.maxFullPrice > item.maxPrice && (
                                    <>
                                      <br />
                                      <s className="old-price">
                                        {formatPrice(
                                          item.maxFullPrice +
                                            (item.maxFullPrice * 10) / 100,
                                          item.currency
                                        )}
                                      </s>
                                    </>
                                  )}
                                </strong>

                                <p className="text-sm">
                                  Excl. GST:{' '}
                                  {formatPrice(item.maxPrice, item.currency)}
                                </p>
                              </Col>
                            )}
                          </Row>
                        ) : (
                          <>
                            <strong>
                              {formatPrice(
                                item.salePrice + (item.salePrice * 10) / 100,
                                item.currency
                              )}
                            </strong>
                            {item.price > item.salePrice && (
                              <strong>
                                &nbsp;
                                <s>
                                  {formatPrice(
                                    item.price + (item.price * 10) / 100,
                                    item.currency
                                  )}
                                </s>
                              </strong>
                            )}

                            <p className="text-sm">
                              Excl. GST:{' '}
                              {formatPrice(item.salePrice, item.currency)}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                </Col>
              )
            })}
          </Row>
        )}
        {element.CTATitle && element.CTALink && (
          <div className="btn-wrapper">
            <Link
              to={element.CTALink}
              target={element.CTATarget}
              className="btn btn-outline-primary btn-w240 mx-auto"
            >
              {element.CTATitle}
            </Link>
          </div>
        )}
      </Container>
      {!!popupItem && (
        <FeaturedProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          item={popupItem}
        />
      )}
    </section>
  )
}
export default ElementFeaturedProducts
