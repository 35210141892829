import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementTwoColumnText = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  var LeftContent = Base64.decode(element.LeftContent)
  var RightContent = Base64.decode(element.RightContent)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-two-columns-text ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        <div className={`title ${element.LayoutClass} ${element.AlignClass}`}>
          {element.SubTitle && (
            <p className="subtitle subtitle-sm">{element.SubTitle}</p>
          )}
          {element.ShowTitle === 1 && <h2>{element.Title}</h2>}
        </div>
        <Row>
          <Col
            className="mb-4 mb-lg-0"
            xs={12}
            lg={6}
            xl={6}
            dangerouslySetInnerHTML={{ __html: LeftContent }}
          />
          <Col
            className="mb-4 mb-lg-0"
            xs={12}
            lg={6}
            xl={6}
            dangerouslySetInnerHTML={{ __html: RightContent }}
          />
        </Row>
        {element.Buttons.length > 0 && (
          <div className="btn-wrapper">
            {element.Buttons.map((button, idx) => (
              <a
                key={`button${idx}`}
                target={button.Target}
                href={button.Link}
                className="btn btn-outline-primary btn-w200 mt-3 mt-lg-4"
              >
                {button.Title}
              </a>
            ))}
          </div>
        )}
      </Container>
    </section>
  )
}

export default ElementTwoColumnText
