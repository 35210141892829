import React from "react"
import ElementContent from "./ElementContent"
import ElementFAQsBlock from "./ElementFAQsBlock"
import ElementTabbedFAQs from "./ElementTabbedFAQs"
import ElementOneColumnText from "./ElementOneColumnText"
import ElementTwoColumnText from "./ElementTwoColumnText"
import ElementThreeColumnText from "./ElementThreeColumnText"
import ElementFourColumnText from "./ElementFourColumnText"
import ElementImageVideoText from "./ElementImageVideoText"
import ElementGalleryImage from "./ElementGalleryImage"
import ElementPromotionImages from "./ElementPromotionImages"
import ElementQuote from "./ElementQuote"
import ElementTable from "./ElementTable"
import ElementMapBlock from "./ElementMapBlock"
import ElementIconColumns from "./ElementIconColumns"
import ElementTestimonials from "./ElementTestimonials"
import ElementVideoBlock from "./ElementVideoBlock"
import ElementLatestBlog from "./ElementLatestBlog"
import ElementCallToAction from "./ElementCallToAction"
import ElementTextColumns from "./ElementTextColumns"
import ElementOurTeam from "./ElementOurTeam"
import ElementCataloguesBlock from "./ElementCataloguesBlock"
import ElementTimelineBlock from "./ElementTimelineBlock"
import ElementTilesBlock from "./ElementTilesBlock"
import ElementUserForm from "./ElementUserForm"
import ElementFeaturedProducts from "./ElementFeaturedProducts"
import ElementMostRecentCatalogue from "./ElementMostRecentCatalogue"
import ElementProjectsSlider from "./ElementProjectsSlider"
import ElementNewsletter from "./ElementNewsletter"
import ElementTextBannerSlider from "./ElementTextBannerSlider"
import ElementDocument from "./ElementDocument"

const Elements = ({data}) => {
    const elements_data = JSON.parse(data)
    return (
            <div>
                {elements_data.map((item) => 
                    renderElement(item)
                )}
            </div>
        )
}

function renderElement(element){
    var elementTemplate
    var className = element.ClassName
    if(className === 'DNADesign\\ElementalVirtual\\Model\\ElementVirtual'){
        var element_data = JSON.parse(element.Data)
        className = element_data.ClassName
    }
    switch(className){
        case 'DNADesign\\Elemental\\Models\\ElementContent':
            elementTemplate = <ElementContent data={element} key={`ElementContent${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementOneColumnText':
            elementTemplate = <ElementOneColumnText data={element} key={`ElementOneColumnText${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTwoColumnText':
            elementTemplate = <ElementTwoColumnText data={element} key={`ElementTwoColumnText${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementThreeColumnText':
            elementTemplate = <ElementThreeColumnText data={element} key={`ElementThreeColumnText${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementFourColumnText':
            elementTemplate = <ElementFourColumnText data={element} key={`ElementFourColumnText${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementImageVideoText':
            elementTemplate = <ElementImageVideoText data={element} key={`ElementImageVideoText${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementGalleryImage':
            elementTemplate = <ElementGalleryImage data={element} key={`ElementGalleryImage${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementFAQsBlock':
            elementTemplate = <ElementFAQsBlock data={element} key={`ElementFAQsBlock${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTabbedFAQs':
            elementTemplate = <ElementTabbedFAQs data={element} key={`ElementTabbedFAQs${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementPromotional':
            elementTemplate = <ElementPromotionImages data={element} key={`ElementPromotionImages${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementQuote':
            elementTemplate = <ElementQuote data={element} key={`ElementQuote${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTable':
            elementTemplate = <ElementTable data={element} key={`ElementTable${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementMapBlock':
            elementTemplate = <ElementMapBlock data={element} key={`ElementMapBlock${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementIconColumns':
            elementTemplate = <ElementIconColumns data={element} key={`ElementIconColumns${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTestimonials':
            elementTemplate = <ElementTestimonials data={element} key={`ElementTestimonials${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementVideoBlock':
            elementTemplate = <ElementVideoBlock data={element} key={`ElementVideoBlock${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementLatestBlog':
            elementTemplate = <ElementLatestBlog data={element} key={`ElementLatestBlog${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementCallToAction':
            elementTemplate = <ElementCallToAction data={element} key={`ElementCallToAction${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTextColumns':
            elementTemplate = <ElementTextColumns data={element} key={`ElementTextColumns${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementOurTeam':
            elementTemplate = <ElementOurTeam data={element} key={`ElementOurTeam${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementCataloguesBlock':
            elementTemplate = <ElementCataloguesBlock data={element} key={`ElementCataloguesBlock${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTimelineBlock':
            elementTemplate = <ElementTimelineBlock data={element} key={`ElementTimelineBlock${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTilesBlock':
            elementTemplate = <ElementTilesBlock data={element} key={`ElementTilesBlock${element.ID}`}/>
            break
        case 'DNADesign\\ElementalUserForms\\Model\\ElementForm':
            elementTemplate = <ElementUserForm data={element} key={`ElementUserForm${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementFeaturedProducts':
            elementTemplate = <ElementFeaturedProducts data={element} key={`ElementFeaturedProducts${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementMostRecentCatalogue':
            elementTemplate = <ElementMostRecentCatalogue data={element} key={`ElementMostRecentCatalogue${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementProjectsSlider':
            elementTemplate = <ElementProjectsSlider data={element} key={`ElementProjectsSlider${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementNewsletter':
            elementTemplate = <ElementNewsletter data={element} key={`ElementNewsletter${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementTextBannerSlider':
            elementTemplate = <ElementTextBannerSlider data={element} key={`ElementTextBannerSlider${element.ID}`}/>
            break
        case 'UnDigital\\Elements\\ElementDocument':
            elementTemplate = <ElementDocument data={element} key={`ElementDocument${element.ID}`}/>
            break
        default: break
    }
    return elementTemplate
}
export default Elements