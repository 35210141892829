import * as React from 'react'
import { Container } from 'react-bootstrap'


const ElementQuote = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    return (
        <section id={`element-${element.ID}`} className={`pt-sm element-quote ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            <Container>
                <div className="quotes">
                    <p className="subtitle subtitle-lg">
                      {element.QuoteText}
                    </p>
                    <p>{element.AuthorName}</p>
                </div>
            </Container>
        </section>
    )
}

export default ElementQuote