import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementImageVideoText = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  var Content = Base64.decode(element.Content)
  return (
    <section
      id={`element-${element.ID}`}
      className={`pt-sm element-image-video-text ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container
        className={`text-and-image ${
          element.Layout === 'TextRight' ? 'image-left' : ''
        }`}
      >
        <Row className="justify-content-center">
          <Col
            xs={12}
            lg={6}
            xl={5}
            className={`${
              element.Layout === 'TextRight' ? 'order-lg-2 order-1' : ''
            }`}
          >
            <div className="text">
              {element.ShowTitle === 1 && (
                <div className="title">
                  <h2>{element.Title}</h2>
                </div>
              )}
              {element.SubTitle && (
                <p className="subtitle subtitle-md">{element.SubTitle}</p>
              )}
              <div dangerouslySetInnerHTML={{ __html: Content }}></div>
              {element.Buttons.length > 0 && (
                <div className="btn-wrapper">
                  {element.Buttons.map((button, idx) => (
                    <a
                      key={`button${idx}`}
                      target={button.Target}
                      href={button.Link}
                      className="btn btn-outline-primary btn-w180"
                    >
                      {button.Title}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </Col>
          <Col
            xs={12}
            lg={6}
            xl={5}
            className={`${
              element.Layout === 'TextRight' ? 'order-lg-1 order-2' : ''
            }`}
          >
            <div className="image">
              <img alt="" src={element.Image} />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default ElementImageVideoText
