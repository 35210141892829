import React, { useMemo, useState } from "react"
import {Container, Tab, Nav, Accordion, Row, Col} from 'react-bootstrap'
import parse from 'html-react-parser'
import Icon from '../../../images/icons.svg'

const ElementTabbedFAQs = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    return (
        <div className={`element elementtabbedfaqs ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>
            {element.ShowTitle === 1 && (<h2 className="text-center mb-3 font-butler">{element.Title}</h2>)}
            <div className="border-bottom pb-5 mb-5 tablist">
                {element.SubTitle && (<h5 className="font-amiri mb-4">{element.SubTitle}</h5>)}
                {element.Tabs.length > 0 && (
                <Tab.Container id="left-tabs-example" defaultActiveKey="tab0">
                    <Row>
                        <Col sm={4}>
                            <Nav className="flex-column bg-grey p-3 mb-3">
                                {element.Tabs.map((tab, idx) => (
                                <Nav.Item key={`nav-tab-${idx}`}>
                                    <Nav.Link eventKey={`tab${idx}`} className="color-black hover-link">{tab.Name}</Nav.Link>
                                </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={8}>
                        <Tab.Content>
                            {element.Tabs.map((tab, idx) => (
                                <Tab.Pane eventKey={`tab${idx}`} key={`pane-tab-${idx}`}>
                                    <div className="noted" 
                                        dangerouslySetInnerHTML={{__html: tab.Content}}>    
                                    </div>
                                    {tab.Items.length > 0 && (
                                    <Accordion defaultActiveKey="tab-accordion-0" flush className="accordion-custom">
                                        {tab.Items.map((tabFAQItem, idx) => (
                                        <Accordion.Item key={`tab-item-${idx}`} eventKey={`tab-accordion-${idx}`}>
                                            <Accordion.Header><h6>{tabFAQItem.Question}</h6></Accordion.Header>
                                            <Accordion.Body>
                                                <div
                                                    dangerouslySetInnerHTML={{__html: tabFAQItem.Answer}}>    
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        ))}
                                    </Accordion>
                                    )}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                )}
            </div>  
        </div>
    )
}
export default ElementTabbedFAQs
