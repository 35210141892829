import React, { useState } from 'react'
import { Container, Row, Col, Modal, Ratio } from 'react-bootstrap'
import { Link } from 'gatsby'
import Icon from '../../../images/icons.svg'
import { Base64 }  from '../../../helpers/base64'

const ElementVideoBlock = ({ data }) => {
  const element = JSON.parse(data.Data)
  var inlineStyle = {}
  var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
  const [modalShow, setModalShow] = useState(false)
  const [popupVideoURL, setPopupVideoURL] = useState(null)

  const handleClick = videoURL => {
    setPopupVideoURL(videoURL)
    setModalShow(true)
  }
  var Content = Base64.decode(element.Content)
  return (
    <section
      id={`element-${element.ID}`}
      className={`${element.FirstItem ? 'bg-gray' : ''} element-videos-block 
        ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`}
      style={inlineStyle}
    >
      <Container>
        {element.ShowTitle === 1 && (
          <div className="title text-center">
            {element.SubTitle && (
              <p className="subtitle subtitle-sm">{element.SubTitle}</p>
            )}
            <h2>{element.Title}</h2>
            {element.Content && (
              <div dangerouslySetInnerHTML={{ __html: Content }} />
            )}
          </div>
        )}
        {element.FirstItem && (
          <Row className="justify-content-center">
            <Col xs={12} lg={10}>
              <div className="video-tile">
                <div className="video-tile-inner">
                  <img src={element.FirstItem.Image} alt="" />
                  <button
                    className="btn-play"
                    onClick={() => handleClick(element.FirstItem.EmbedVideoURL)}
                  >
                    <svg focusable="false" width="18" height="16">
                      <use xlinkHref={`${Icon}#icon-play`}></use>
                    </svg>
                  </button>
                </div>
                <p className="text-center">{element.FirstItem.Title}</p>
              </div>
            </Col>
          </Row>
        )}
        {!!element.Items.length && <Row>
          {element.Items.map((item, idx) => {
          const colSize = element.Items.length > 1 ? 6 : 12
          return (
            <Col key={`video-item-${item.ID}`} xs={colSize} lg={colSize}>
              <div className="video-tile">
                <div className="video-tile-inner">
                  <img src={item.Image} alt="" />
                  <button
                    className="btn-play"
                    onClick={() => handleClick(item.EmbedVideoURL)}
                  >
                    <svg focusable="false" width="18" height="16">
                      <use xlinkHref={`${Icon}#icon-play`}></use>
                    </svg>
                  </button>
                </div>
                <p>{item.Title}</p>
              </div>
            </Col>
            )
          })}
        </Row>}
        {element.CTATitle && element.CTALink && (
          <div className="btn-wrapper">
            <Link
              to={element.CTALink}
              target={element.CTATarget}
              className="btn btn-outline-primary btn-w230 mx-auto"
            >
              {element.CTATitle}
            </Link>
          </div>
        )}
      </Container>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Ratio aspectRatio="16x9">
            <iframe
              width="100%"
              height="100%"
              src={popupVideoURL}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Ratio>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default ElementVideoBlock
