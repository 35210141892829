import * as React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Base64 }  from '../../../helpers/base64'

const ElementPromotionImages = ({data}) => {
    const element = JSON.parse(data.Data) 
    var inlineStyle = {}
    var blackBGClass = ''
    if (element.BackgroundColour != null) {
      inlineStyle.backgroundColor = element.BackgroundColour
      if(element.BackgroundColour === '#000000'){
          blackBGClass = ' section-black'
      }
    }
    var Content = Base64.decode(element.Content)
    return (
        <section id={`element-${element.ID}`} className={`bg-gray element-promotion-images ${blackBGClass} ${element.PaddingTop} ${element.PaddingBottom}`} style={inlineStyle}>    
            <Container>
                <div className="title text-center">
                    {element.SubTitle && (<p className="subtitle subtitle-sm">{element.SubTitle}</p>)}
                    {element.ShowTitle === 1 && (<h2>{element.Title}</h2>)}
                    {Content && (
                        <div dangerouslySetInnerHTML={{__html: Content}}/>
                    )}
                </div>
                <Row>
                    <Col xs={12} lg={8}>
                        <a href={element.Image1Link} target={element.Image1LinkTarget} className="promotional-big">
                            <img alt="" src={element.Image1} />
                        </a>
                    </Col>
                    <Col xs={12} lg={4}>
                        <div className="promotional-small">
                            <a href={element.Image2Link} target={element.Image2LinkTarget} className="promotional-small-top">
                                <img alt="" src={element.Image2} />
                            </a>
                            <a href={element.Image3Link} target={element.Image3LinkTarget} className="promotional-small-bottom">
                                <img alt="" src={element.Image3} />
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default ElementPromotionImages